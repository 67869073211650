module.exports = [{
      plugin: require('/Users/matt/code/devmatt-web/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('/Users/matt/code/devmatt-web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47519312-7"},
    },{
      plugin: require('/Users/matt/code/devmatt-web/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://devmatt.com"},
    },{
      plugin: require('/Users/matt/code/devmatt-web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
